import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";

import activeLeftArrow from "./../../../assets/images/arrow/active/basicLeftArrow.svg";
import activeRightArrow from "./../../../assets/images/arrow/active/basicRightArrow.svg"
import DateCalender from "./component/DateCalender/DateCalender";
import { DATE_FORMATS } from "../../constants/dateFormats";

import styles from "./dateFilter.module.css";

const DateFilter = ({
        startDate = moment().subtract(6).format(DATE_FORMATS.MONTH_DAY_YEAR), 
        endDate = moment().format(DATE_FORMATS.MONTH_DAY_YEAR),
        format = DATE_FORMATS.MONTH_DAY_YEAR,
        handleDateUpdateAction = () => {},
    }) =>{

    const [displayCalender, setDisplayCalender] = useState(false);

    const handleToggleCalenderDisplay = useCallback(()=>{
        setDisplayCalender(!displayCalender);
    },[setDisplayCalender, displayCalender]);

    const formattedStartDate = useMemo(()=>{
        return moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).format(format);
    },[startDate, format])

    const formattedEndDate = useMemo(()=>{
        return moment(endDate,DATE_FORMATS.MONTH_DAY_YEAR).format(format);
    },[endDate,format])

    return (
        <div className={styles.container}>
            <div className={styles.dateLabel} onClick={handleToggleCalenderDisplay}>
                <img src={activeLeftArrow} />
                <div className={styles.dateContentContainer}>
                    <span>{formattedStartDate}</span>
                    <span>-</span>
                    <span>{formattedEndDate}</span>
                </div>
                <img src={activeRightArrow} />
            </div>
            {displayCalender && 
                <DateCalender 
                    handleDateUpdateAction={handleDateUpdateAction} 
                    startDate={startDate}
                    handleToggleCalenderDisplay={handleToggleCalenderDisplay}
                />}
        </div>
    )
}

DateFilter.defaultProps={
    
}

export default DateFilter;