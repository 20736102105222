import React from "react";

import styles from "./header.module.css"

const Header = ({ buttonContent, label="", secondaryContent="", handleNotesDisplayAction}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.primaryText}>
                    {label}
                </div>
                <div className={styles.secondaryText}>
                    {secondaryContent}
                </div>
            </div>
            <div className={styles.button} onClick={handleNotesDisplayAction}>
                {buttonContent}
            </div>
        </div>
    )
}

export default Header;