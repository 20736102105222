import React from "react";

import styles from "./dateDisplay.module.css"

const DateDisplay = ({startDate, endDate})=>{
    return (
        <div className={styles.container}>
            <div className={styles.mainLabel}>
                Current Week
            </div>
            <div className={styles.dateContent}>
                <span>{startDate}</span>
                <span>-</span>
                <span>{endDate}</span>
            </div>
        </div>
    )
}

export default DateDisplay;