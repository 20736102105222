import React from "react";
import { isNil } from "lodash";

import styles from "./indicatorOption.module.css";

const IndicatorOption = ({color,content, timeInRange}) =>{

    const colorOption = {
        inRange : styles.inRange,
        belowRange : styles.belowRange,
        aboveRange : styles.aboveRange
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.strip} ${colorOption[color]}`} />
            <div>
                {content}
            </div>
            <div className={styles.valueTypo}>
                {!isNil(timeInRange?.glucose) ? `${timeInRange?.glucose} %`: "-"}
            </div>
        </div>
    )
}

export default IndicatorOption;