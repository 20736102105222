import React, { useCallback } from "react";

import { HEADER_ENUMS } from "./header.constants";
import WeeklyFilter from "../../../../../progressMonitoring/components/WeeklyFilter/WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../helpers/dateFormatter";
import { DURATION_CHANGE_TYPES } from "../../../../../../patientProfile.constants";

import styles from "./header.module.css";

const Header = ({ handleOverAllDurationChange,  overViewDurationTypes, name = DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS}) =>{

    const handleWeeklyDurationChange = useCallback((name , previous)=>{
        handleOverAllDurationChange({name , prev : previous})
    },[handleOverAllDurationChange]);

    return (
        <div className={styles.container}>
            <div className={styles.mainContentContainer}>
                <div className={styles.mainLabel}>
                    {HEADER_ENUMS.LABEL}
                </div>
                <div className={styles.mainContent}>
                    {HEADER_ENUMS.CONTENT}
                </div>
            </div>
            <div className={styles.rightContainer}>
                <WeeklyFilter previous={overViewDurationTypes?.[DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS] || 0} name={name} handlePrimaryDurationChange={handleWeeklyDurationChange} {...getWeeklyFormattedWeeklyDates({prev : overViewDurationTypes?.[DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS],format:"ddd, MMM DD"})} />
            </div>
        </div>
    )
}

export default Header;