import React, { useCallback } from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./dailyMonitoringLog.module.css";

const DailyMonitoringLog = ({tableData,wrapperFields,handleOverDurationChange, overViewDurationTypes }) =>{

    return (
        <div className={styles.container}>
            <Header handleOverAllDurationChange={handleOverDurationChange} overViewDurationTypes={overViewDurationTypes} />
            <Body handleOverAllDurationChange={handleOverDurationChange} overViewDurationTypes={overViewDurationTypes} tableData={tableData} wrapperFields={wrapperFields}/>
        </div>
    )
}

export default DailyMonitoringLog;