import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { progressMonitoringType } from '../../../../../../../store/progressMonitoringAtoms/progressMonitoringType';
import WeeklyFilter from '../WeeklyFilter';
import { getWeeklyFormattedWeeklyDates } from '../../../../../../helpers/dateFormatter';

import styles from './header.module.css';
import DateFilter from '../../../../../../molecules/DateFilter';
import moment from 'moment';

export default function Header({
    progressMonitoringType: incomingProgressMonitoringType,
    handleProgressMonitoringDurationChange,
    filterDuration
  }) {

  const [monitoringType,setMonitoringType]=useRecoilState(progressMonitoringType);

  useEffect(()=>{
    setMonitoringType(incomingProgressMonitoringType);
  },[])

  const handleFilterDurationChange = useCallback((updatedStartDate)=>{
    handleProgressMonitoringDurationChange({ updatedStartDate } )
  },[handleProgressMonitoringDurationChange]);

  return (
    <div className={styles.container}>
      <div className={styles.headingMain}>
        <div className={styles.headingMainText}>
          Glucose
        </div>
        <DateFilter 
          handleDateUpdateAction={handleFilterDurationChange} 
          startDate={filterDuration} 
          format={"ddd, MMM DD"} 
          endDate={moment(filterDuration,"MM-DD-YYYY").add(6,'d').format("MM-DD-YYYY")}
        />
      </div>
    </div>
  );
}
