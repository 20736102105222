
import nonActiveLeft from "./../../../../../assets/images/arrow/nonActive/nonActiveLeft.svg";
import activeLeft from "./../../../../../assets/images/arrow/active/basicLeftArrow.svg";

import nonActiveRight from "./../../../../../assets/images/arrow/nonActive/nonActiveRight.svg";
import activeRight from "./../../../../../assets/images/arrow/active/basicRightArrow.svg";

import styles from "./pagination.module.css"

const Pagination = ({totalPages = 1, currentPagination, handlePagincationAction}) => {
    const handleForwardAction = ()=>{
        handlePagincationAction(currentPagination+1);
    };

    const handleBackwardAction = ()=>{
        handlePagincationAction(currentPagination-1);
    };

    return (

        <div className={styles.pagination}>
                <div className={styles.paginationContainer}>
                    <img onClick={handleBackwardAction} className={styles.arrow} src={currentPagination<=1 ? nonActiveLeft : activeLeft}></img>
                    <div className={styles.pageNumber}>
                        {currentPagination}
                    </div>
                    <img onClick={handleForwardAction} className={styles.arrow} src={currentPagination>=totalPages? nonActiveRight: activeRight}></img>
                </div>
            </div>

    )
}

export default Pagination;
