import React from "react";

import WeeklyFilter from "../../../WeeklyFilter";

import styles from "./comparisonWeek.module.css";

const ComparisonWeek = (props) =>{
    return (
        <div className={styles.container}>
            <div className={styles.mainLabel}>
                Comparison Week
            </div>
            <WeeklyFilter {...props}/>
        </div>
    )
}

export default ComparisonWeek;