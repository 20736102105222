const trendAnalysisPlotLines = [
  {
    value: 50,
    color: '#EAE7EC',
    width: 1,
  },
  {
    value: 63,
    color: '#9EDBC5',
    dashStyle: 'longdash',
    width: 1,
  },
  {
    value: 140,
    color: '#9EDBC5',
    dashStyle: 'longdash',
    width: 1,
}];

const trendAnalysisPlotBands =[{
    from: 63,
    to: 140,
    color: '#84EBC514',
    label: {
        text: '',
        style: {
            color: '#84EBC514'
        }
    }
}];

const plotOptions = {
    spline: {
      lineWidth: 2.5,
      states: {
        hover: {
          lineWidth: 2.5
        }
      },
      marker: {
        enabled: false
      },
      pointInterval: 1,
      pointStart: 0
    }
}


export {
    trendAnalysisPlotLines,
    trendAnalysisPlotBands,
    plotOptions
}