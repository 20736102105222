import React from "react";
import {map} from "lodash";
import moment from "moment";

import billingIcon from "./../../../../../../../../../assets/images/billing_icon.svg";

import styles from "./billingList.module.css";

const BillingList = ({listItems })=>{
    return (
        <div className={styles.container}>
            {map(listItems,(listItem)=>{
                const {billingDate="", careProUserName="", nextBillingDate="-", type="_"} = listItem;
                let label = type.split("_").join(" ");

                return (
                    <div className={styles.listItemContainer}>
                        <div className={styles.iconContainer}>
                            <img src={billingIcon} className={styles.billingIcon} />
                        </div>
                        <div className={styles.rightItemContainer}>
                            <div className={styles.rightTopContainer}>
                                <div className={styles.mainText}>{label || "-"}</div>
                            </div>
                            <div className={styles.rightBottomContainer}>
                                <div className={styles.textOuterContainer}>
                                    <div className={styles.nonHighlightedText}>Created By</div>
                                    <div className={styles.listItemBaseContent}>{careProUserName}</div>
                                </div>
                                <div className={styles.textOuterContainer}>
                                    <div className={styles.nonHighlightedText}>Submitted on</div>
                                    <div className={styles.listItemBaseContent}>{moment(billingDate).format("ddd, MMM DD")}</div>
                                </div>
                                <div className={styles.textOuterContainer}>
                                    <div className={styles.nonHighlightedText}>Next Billing Cycle</div>
                                    <div className={styles.listItemBaseContent}>{moment(nextBillingDate).format("ddd, MMM DD")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default BillingList