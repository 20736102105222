import React from "react";

import CustomBasicDropdown from "../../../../molecules/baseComponents/dropDowns/customBasicDropdown";
import styles from "./insulinStatus.module.css";

const InsulinStatus = ({label,handleInsulinStatusUpdate,...rest}) =>{

    const handleFieldChange = ()=>(value)=>{
        handleInsulinStatusUpdate(value);
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.innerClass}>
                {label}
            </div>
            <div className={styles.boldFontContainer}>
                <CustomBasicDropdown handleFieldChange={handleFieldChange} {...rest} componentContainer={styles.componentContainer}/>
            </div>
        </div>
    )
}

export default InsulinStatus