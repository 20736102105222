import React from "react";

import styles from "./footer.module.css";

const Footer = ({handleToggleCalenderDisplay, handleDateUpdateAction})=>{
    return (
        <div className={styles.container}>
            <div className={styles.cancelButton} onClick={handleToggleCalenderDisplay}>
                Cancel
            </div>
            <div className={styles.applyButton} onClick={handleDateUpdateAction}>
                Apply
            </div>
        </div>
    )
}

export default Footer;