import { hasChartData } from "../../helpers/chart";
import { SERIES_TYPES } from "./trendAnalysis.constants";

const getMinAndMaxGraphRange = (rangeVal=[])=>{
    const minRange = [] ,maxRange = [];
    rangeVal.forEach((val)=>{
        if(val!=""){
            minRange.push(10);
            maxRange.push(28);
        }
        else{
            minRange.push("");
            maxRange.push("");
        }
    })

    return {
        minRange,
        maxRange
    }
}

const getCorrespondingSeriesConfigs = ({type,data,...rest})=>{
    switch(type){
        case SERIES_TYPES.SPLINE_CHART:
            return {
                data: hasChartData(data) ?  data?.map((value) => (value?.["glucose_level"] || null)) : [],
                zones: [{
                    value: 63,
                    color: '#EE9887'
                    }, {
                    value: 140,
                    color: '#7ACCAE'
                    }, {
                    value: 300,
                    color: '#E4A944'
                }]
            }
            break;
        case SERIES_TYPES.DAILY_MAX_POINTS:
        case SERIES_TYPES.DAILY_MIN_POINTS:
            const {trendAnalysisRangeData = []} = rest || {};
            return {
                data,
                marker: {
                    enabled: false
                },
                states: {
                    hover: {
                        enabled: false
                    }
                },
                lineWidth: 0,
                tooltip:{
                    enabled : false
                },
                dataLabels: {
                    enabled: true,
                    formatter: function() {
                        return trendAnalysisRangeData?.[this.index];
                    },
                    style: {
                        fontSize: '14px', // Set font size
                        color: '#66636D' // Set font color
                    }
                }
            }    
            break;
    }
}

const getRespectiveSeries = (chartData)=>{
    return {
        name : "",
        showInLegend: false,
        ...getCorrespondingSeriesConfigs(chartData),
    }
}

export {
    getMinAndMaxGraphRange,
    getRespectiveSeries
}