const WEEK_NAME = [{
        value : "S"
    },
    {
        value : "M"
    },
    {
        value : "T"
    },
    {
        value : "W"
    },
    {
        value : "T"
    },
    {
        value : "F"
    },
    {
        value : "S"
    }
]

export {
    WEEK_NAME
}