import React from "react";

import { HEADER_ENUMS } from "../../receentActivity.constants";

import styles from "./header.module.css";

const Header = ({containerClass, heading, hasSubHeading=true, labelContainerClass=""}) =>{
    return (
        <div className={`${styles.container} ${containerClass}`}>
            <div className={styles.leftContainer}>
                <div className={`${styles.mainLabel} ${labelContainerClass}`}>{heading ? heading:  HEADER_ENUMS.RECENT_ACTIVITY_HEADER}</div>
                <div className={styles.mainContent}>{hasSubHeading && HEADER_ENUMS.RECENT_ACTIVITY_CONTENT}</div>
            </div>
        </div>
    )
}

export default Header;