import React, { useCallback, useState } from "react";

import Body from "./components/Body";
import Footer from "./components/Footer";

import styles from "./dateCalender.module.css";

const DateCalender = ({startDate, handleDateUpdateAction, handleToggleCalenderDisplay})=>{
    
    const [currentStartDate , setCurrentStartDate] = useState(startDate);
    const [currentBack, setCurrentBack] = useState(0);

    const handleCurrentDateUpdate = useCallback((updatedDate)=>{
        setCurrentStartDate(updatedDate);
    },[])

    const handleUpdateDateToCurrentDateAction = useCallback(()=>{
        handleDateUpdateAction(currentStartDate);
        handleToggleCalenderDisplay();
    },[handleDateUpdateAction,currentStartDate, handleToggleCalenderDisplay]);

    return (
        <div className={styles.container}>
            <Body 
                startDate={currentStartDate}
                currentBack={currentBack}
                setCurrentBack={setCurrentBack}
                handleCurrentDateUpdate={handleCurrentDateUpdate}
            />
            <Footer 
                handleToggleCalenderDisplay={handleToggleCalenderDisplay} 
                handleDateUpdateAction={handleUpdateDateToCurrentDateAction}
            />
        </div>
    )
}

DateCalender.defaultProps={

}

export default DateCalender;