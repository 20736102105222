import ChangeFactor from "./components/changefactor/ChangeFactor";

import { PROGRESS_MONITORING_TYPE } from "../progressMonitoring/progressMonitoring.constants";
import BloodGlucose from "./components/bloodGlucose/BloodGlucose";

import BalancePlateCard from "./components/balancePlateCard/BalancePlateCard";
import { getBalancedPlateHeader } from "./overviewTav.components";

const balancedPlateGroup = [['avgPlateScore'],["CALORIES","CARBOHYDRATES","PROTEINS","FATS","FIBER","SODIUM"]];

const statCards = [
    {
        headerProps : {
            label : "Average Continuous Glucose Summary",
            content : "Overview of Continuious Glucose Levels based on time of day",
        },
        CustomComponent : BloodGlucose,
        footerProps :{
            FooterComponent : ChangeFactor,
        },
        bodyStatFields :[
            'AVG_FASTING_GLUCOSE',
            'FASTING_TIME_IN_RANGE',
        ],
        chartFields : "FASTING_GLUCOSE",
        name : PROGRESS_MONITORING_TYPE.BLOOD_GLUCOSE,
    },
    {
        headerProps : {
            label : getBalancedPlateHeader(),
            content : "Breakdown of meal composition score of different meal",
        },
        CustomComponent : BalancePlateCard,
        footerProps :{
            FooterComponent : ChangeFactor,
        },
        bodyStatFields :[
            'AVG_BALANCED_PLATE',
            'AVG_BALANCED_PLATE',
        ],
        chartFields : "OVERALL_TIME_IN_RANGE",
        name : PROGRESS_MONITORING_TYPE.BALANCED_PLATE,
        statistics : [
            {
              "name": "avgPlateScore",
              label :"Avg. Plate Score/day",
              "color": "#EAC56B"
            },
            {
              "name": "avgCalories",
              label: "Avg. Calories/day",
              "color": "#F6B4A7"
            }
          ]
    },
];

export {
    statCards,
    balancedPlateGroup
}