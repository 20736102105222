import React from "react";
import {map, isNil} from "lodash";
import cx from "classnames";

import TirSingleLineChart from "../../../../../../charts/TirSingleLineChart";
import { getFormattedTableData } from "./bloodGlucose.helper";
import CardNoDataFallback from "../../../../../../molecules/cardNoDataFallback/CardNoDataFallback";

import styles from "./bloodGlucose.module.css";

const COLOR_MAPPINGS ={
    red : styles.red,
    green : styles.green,
    yellow : styles.yellow,
}

const BloodGlucose = ({bloodGlucoseMetrics=[]}) =>{

    if(bloodGlucoseMetrics?.length == 0){
        return <CardNoDataFallback  textClass={styles.secondaryTextClass} containerClass={styles.noDataContainerClass}/>
    }

    return (
        <div className={styles.container}>
            {map(bloodGlucoseMetrics,(option)=>{
                const {allData=[], label="", mainContent = []}= option || {};
                return (
                    <div className={styles.chartContainer}>
                        <div className={styles.labelContainer}>
                            <div className={styles.label}>
                                {label}
                            </div>
                        </div>
                        <div className={styles.mainTableBody}>
                            <TirSingleLineChart tableData={getFormattedTableData(allData)} />
                            <div className={styles.lowerTextContainer}>
                                {map(mainContent,({contentLabel = "", contentValue ="", suffix="", color="green"})=>{
                                    return (
                                        <div className={cx(styles.mainContentContainer,COLOR_MAPPINGS[color])}>
                                            <div className={styles.contentLabel}>
                                                {contentLabel}
                                            </div>
                                            {
                                                contentValue ? <div className={styles.contentValueContainer}>
                                                    <span className={styles.contentValue}>{contentValue}</span>
                                                    <span>{suffix}</span>
                                                </div> :
                                                <div className={cx(styles.contentValueContainer, styles.noDataTypo)}>
                                                    NO DATA
                                                </div>
                                            }
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default BloodGlucose;