const DURATION_OPTIONS = [{
    value :7,
    label : "Last 7 days"
},
{
    value :14,
    label : "Last 14 days"
},
{
    value :21,
    label : "Last 21 days"
}];

const NOTES_CONTENT_MAPPING ={
    HEADER : {
        LABEL : "Smart Notes",
        CONTENT : "Recent notes from all coaches & care providers",
    }
}

const BALANCED_PLATE_MACRO_NUTRIETS ={
    avgPlateScore : "Avg. Plate Score",
    PROTEINS : "Protien",
    SODIUM : 'Sodium',
    FIBER : 'Fiber',
    CALORIES : 'Calories',
    CARBOHYDRATES : 'Carbs',
    FATS : 'Fat',
}

const BALANCED_PLATE_TYPE_TO_COLOR_MAPPING ={
    CRITICAL: "red",
    UNBALANCED : "yellow",
    BALANCED : "green"
}

const BALANCED_PLATE_MACRO_NUTRIETS_SUFFIX = {
    CALORIES : "kcal",
    SODIUM : "g",
    avgPlateScore : " "
}

export {
    DURATION_OPTIONS,
    BALANCED_PLATE_MACRO_NUTRIETS,
    BALANCED_PLATE_MACRO_NUTRIETS_SUFFIX,
    BALANCED_PLATE_TYPE_TO_COLOR_MAPPING,
    NOTES_CONTENT_MAPPING
}