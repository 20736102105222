import React, { useMemo } from "react";
import {map} from "lodash";
import moment from "moment";

import { getDateFormattedData, getDateMappedToClass, getStartDateFormattedData, getWeeklyClassMapping } from "./monthCalender.helpers";
import { WEEK_NAME } from "./monthCalender.mapping";

import CalenderBlock from "./components/CalenderBlock/CalenderBlock";

import styles from "./monthCalender.module.css";

const MonthCalender = ({monthBack=0, startDate, endDate, handleUpdateStartDate}) =>{

    const weekNameData = useMemo(()=> getWeeklyClassMapping(WEEK_NAME),[getWeeklyClassMapping]);

    const currentMonth = useMemo(()=>{
        const month = moment().subtract(monthBack,"M").format("MMMM");
        return month;
    },[monthBack])

    const monthData = useMemo(()=>{
        let dateData = getDateFormattedData(monthBack, handleUpdateStartDate);
        const dateFormattedData = getStartDateFormattedData(dateData,startDate, endDate, monthBack);
        return getDateMappedToClass(dateFormattedData);
    },[monthBack, getDateFormattedData, getDateMappedToClass, startDate,endDate, handleUpdateStartDate])

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                {currentMonth}
            </div>
            <div className={styles.dateDataContainer}>
                {map(weekNameData,(weekData)=>{
                    return <CalenderBlock {...weekData} />
                })}
                {map(monthData,(monthDateData)=>{
                    return <CalenderBlock {...monthDateData} />
                })}
            </div>
        </div>
    )
}

export default MonthCalender;