import React from "react"

import styles from "./overViewTab.module.css"

const getBalancedPlateHeader = () =>{
    return (
        <div className={styles.balancedPlateLabelContainer}>
            <span className={styles.mainLabel}>Balanced Plate Summary</span>
            <div className={styles.betaIconContainer}>
                Beta
            </div>
        </div>
    )
}

export {
    getBalancedPlateHeader
}