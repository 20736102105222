import moment from "moment";
import { DATE_FORMATS } from "../../../../../../constants/dateFormats";

const getRespectiveStartDate = ({startDate=""})=>{
    const endDate = moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).add(6,'days').format(DATE_FORMATS.MONTH_DAY_YEAR);
    return {
        endDate,
        startDate
    }
}

export {
    getRespectiveStartDate
}