const MAPPED_METRIC_TYPES={
    AVG_FASTING_GLUCOSE : "Fasting",
    AVERAGE_GLUCOSE : "Average Glucose",
    FASTING_TIME_IN_RANGE: "Fasting",
    OVERALL_TIME_IN_RANGE : "Time In Range",
    POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE : "PP",
    AVERAGE_ONE_HOUR_POST_PRANDIAL: "PP"
};

const MEAL_TYPES ={
    DINNER : 'Dinner',
    BREAKFAST : "Breakfast",
    LUNCH : "Lunch",
    FASTING : "Fasting"
}

export {
    MAPPED_METRIC_TYPES,
    MEAL_TYPES
}
