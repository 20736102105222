import { useState, useMemo } from "react";
import Table from "./components/table/Table";
import Search from "./components/search/Search"
import Pagination from "../pagination";
import EmptyBag from "../emptyBag/EmptyBag"
import { useMemberForm } from "./members.async";
import getSearchTextFilteredList from "./member.helpers";

const Members = ({ memberList, practiceName, updateToast, worksiteList, practiceId, setRefreshMemberList, setIsLoading }) => {

    const [formValues, submitFormValues, updateFormValues] = useMemberForm(practiceId, worksiteList.length > 0 ? worksiteList[0]["id"] : []);

    const [currentPagination, setCurrentPagination] = useState(1);

    const handleShowPreviousListItems = () => {
        if (currentPagination == 1) {
            return;
        }
        setCurrentPagination(currentPagination - 1);
    }

    const handleShowNextListItems = () => {
        if ((memberList.length <= (10 * currentPagination))) {
            return
        }
        setCurrentPagination(currentPagination + 1);
    }

    const [ searchText, updateSearchText] = useState("");

    const filteredList = useMemo(()=>getSearchTextFilteredList(memberList,searchText),[memberList,searchText]);

    const paginatedList = filteredList?.slice(10 * (currentPagination - 1), Math.min(memberList.length, 10 * (currentPagination))) || [];

    return (
        <div>
            <Search updateSearchText={updateSearchText} setIsLoading={setIsLoading} updateToast={updateToast} practiceId={practiceId} worksiteList={worksiteList} setRefreshMemberList={setRefreshMemberList} updateFormValues={updateFormValues} submitFormValues={submitFormValues}></Search>
            {filteredList.length > 0 && <Table practiceId={practiceId} listItems={paginatedList} practiceName={practiceName} updateToast={updateToast} setRefreshMemberList={setRefreshMemberList}></Table>}
            {filteredList.length > 0 && <Pagination totalPages={Math.ceil(filteredList?.length/10)} currentPage={currentPagination} handleShowNextListItems={handleShowNextListItems} handleShowPreviousListItems={handleShowPreviousListItems} ></Pagination>}
            {filteredList.length == 0 && <EmptyBag setIsLoading={setIsLoading} updateToast={updateToast} setRefreshMemberList={setRefreshMemberList} practiceId={practiceId} updateFormValues={updateFormValues} submitFormValues={submitFormValues} worksiteList={worksiteList}></EmptyBag>}
        </div>


    )
}

export default Members;
