import React from "react";
import { map } from "lodash";
import cx from "classnames";
import moment from "moment";

import { MEDICATION_TRACER_FORMAT } from "../../../../../../constants/metricTypes";
import styles from "./body.module.css";
import RecentActivity from "../../../recentActivity";
import TrendAnalysis from "../../../../../../charts/TrendAnalysis/TrendAnalysis";
import ActivityAnalysis from "../../../../../../charts/ActivityAnalysis/ActivityAnalysis";
import ComparisonAnalysisTable from "./components/ComparisonAnalysisTable";
import GlucoseMetricsCard from "./components/GlucoseMetricsCard";
import IndicatorOption from "./components/indicatorOption/IndicatorOption";
import TirCard from "./components/TirCard/TirCard";
import exclamationIcon from "./../../../../../../../assets/images/exclamation/greyExclamation.svg";
import TrendAnalysisTable from "./components/TrendAnalysisTable";
import { getSummaryCopyAction } from "./body.helpers";

const Body = ({
    summary,
    categories,
    handleToastUpdateAction,
    activityAnalysisData=[],
    comparisonAnalysisColumns = {},
    tableChartData,
    trendAnalysisTableData,
    handleGlucoseLogsTabUpdateAction,
    glucoseLogMetricsSourceTab,
    trendAnalysisMin,
    trendAnaylsisMax,
    trendAnalysisIndicators = [
      { content: "Low", color: "belowRange", type : "IN_RANGE_LESS" },
      { content: "In Range", color: "inRange", type : "IN_RANGE_BETWEEN"},
      { content: "High", color: "aboveRange", type : "IN_RANGE_MORE"},
    ],
    activeDurations = MEDICATION_TRACER_FORMAT.WEEKLY,
    primaryMonitoringOptions = [
      {
        CustomComponent: GlucoseMetricsCard,
        heading: "Glucose Metrics",
        containerClass: styles.containerClass,
      },
      {
        CustomComponent: TirCard,
        heading: "Time-in-Range",
        containerClass: styles.containerClass,
      },
      3
    ],
    trendAnalysisMainData,
    handleProgressMonitoringDurationChange = () => {},
    recentTrends,
    filterDuration
  }) => {

  const handlePrimaryDurationChange = (name, prev = moment().format('MM-DD-YYYY')) => {
    handleProgressMonitoringDurationChange({ name, updatedStartDate : prev });
  };

  const handleSummaryCopyAction=()=>{
    getSummaryCopyAction({summaryData : summary, prev: filterDuration?.current || 0, recentTrends, handleToastUpdateAction})
  }

  return (
    <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.primaryText}>
                          <span className={styles.primaryText}>Overall Glucose Summary</span>
                          <img src={exclamationIcon} className={styles.exclamationIcon}/>
                    </div>
                    <div className={styles.secondaryText}>
                      Macro Level Overview on Blood Glucose Tracking
                    </div>
                </div>
                <div>
                  <div className={styles.copyDataOuterContainer} onClick={handleSummaryCopyAction}>
                        <div className={styles.copyDataInnerContainer}>Copy Data</div>
                  </div>
                </div>
            </div>
            <div className={styles.primaryLogsInnerContainer}>
              {map(
                primaryMonitoringOptions,
                ({ CustomComponent, heading="Glucose Trend", containerClass = styles.containerClass, labelContainerClass = styles.labelContainerClass }) => (
                  <RecentActivity
                    key={heading}
                    className={styles.cardContainerClass}
                    data={summary}
                    bodyProps={CustomComponent ? { CustomComponent } : {}}
                    headerProps={{ heading, hasSubHeading:false, containerClass, labelContainerClass }}
                    recentTrends={recentTrends}
                  />
                )
              )}
            </div>
          </div>
          
          <TrendAnalysisTable
            previous={filterDuration?.current || 0}
            trendAnalysisTableData={trendAnalysisTableData} 
            handleToastUpdateAction={handleToastUpdateAction}
            glucoseLogMetricsSourceTab={glucoseLogMetricsSourceTab}
            handleGlucoseLogsTabUpdateAction={handleGlucoseLogsTabUpdateAction}
          />
          

          <div className={cx(styles.primaryLogsContainer, styles.activityAnalysisContainer)}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.primaryText}>
                          <span className={styles.primaryText}>Weekly Glucose Trend</span>
                          <img src={exclamationIcon} className={styles.exclamationIcon}/>
                    </div>
                    <div className={styles.secondaryText}>
                      Visual trend of Glucose level across the week
                    </div>
                </div>
            </div>
            <TrendAnalysis
              trendAnalysisMin={trendAnalysisMin}
              trendAnalysisMax={trendAnaylsisMax}
              categories={categories}
              comparisonAnalysisData={trendAnalysisMainData}
            />
            <div className={styles.trendAnalysisIndicator}>
              {map(trendAnalysisIndicators, (field) => {
                const {overallTimeInRange} =summary || {};
                const {metricsNameGlucoseDetailsMap={}} = overallTimeInRange || {};
                const {type} = field;
                let timeInRange;
                Object.entries(metricsNameGlucoseDetailsMap).forEach(([key,value])=>{
                  if(key.includes(type)){
                    timeInRange=value;
                  }
                })

                return <IndicatorOption key={field.content} {...field} timeInRange={timeInRange}/>
              })}
            </div>
          </div>

          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.primaryText}>
                          <span className={styles.primaryText}>Weekly Glucose Comparison</span>
                          <img src={exclamationIcon} className={styles.exclamationIcon}/>
                    </div>
                    <div className={styles.secondaryText}>
                      Comparative breakdown of glucose time-in-range across different time segments
                    </div>
                </div>
            </div>
            <ComparisonAnalysisTable
              handleProgressMonitoringDurationChange={handlePrimaryDurationChange}
              columnsData={comparisonAnalysisColumns}
              tableChartData={tableChartData}
              filterDuration={filterDuration?.comparisonAnalysis}
            />
          </div>

          <div className={styles.primaryLogsContainer}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.primaryText}>
                          <span className={styles.primaryText}>Ambulatory Glucose Profile (AGP)</span>
                          <img src={exclamationIcon} className={styles.exclamationIcon}/>
                    </div>
                    <div className={styles.secondaryText}>
                      Composite view of glucose levels throughout a typical day
                    </div>
                </div>
            </div>
            <div className={styles.activityAnalysisTableContainer}>
              <ActivityAnalysis {...activityAnalysisData} />
            </div>
          </div>

        </div>
    </div>
  );
};

export default Body;
