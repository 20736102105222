import React from "react";
import {map} from "lodash";

import styles from "./comparisonAnalysisTable.module.css";
import BaseTable from "../../../../../../../tables/BaseTable";
import { getTableFields } from "./comparisonAnalysisTable.fields";
import { getCombinedData, getFormattedData, getGlucoseFormatedMapper, getMapToObjectFormat } from "./comparisonAnalysisTable.helper";
import { getColumnWrapperFields, getWrapperFields } from "./comparisonAnalysisTable.tabs";
import { getBasicMapDataFormatter } from "../../../../../../../../dataFormatter/glucoseMetricsFormatter";

const ComparisonAnalysisTable = ({
        tableChartData=[],
        columnsData: mainColumnData,
        columns=["gant1","gant2"],
        handleProgressMonitoringDurationChange,
        filterDuration
    }) =>{
    const columnData = getTableFields(handleProgressMonitoringDurationChange, filterDuration);

    const mappedData = map(tableChartData?.slice(0,2),(data)=>{
        return getBasicMapDataFormatter(data)
    });

    const secondaryMapped = map(tableChartData.slice(2,4),(data)=>{
        return getGlucoseFormatedMapper(data)
    });

    const mapToObjectData = map(mappedData,(data,key)=>{
        return getMapToObjectFormat(data,columns[key])
    })

    const secondaryMappedData = map(secondaryMapped,(data,key)=>{
        return getMapToObjectFormat(data,columns[key])
    })

    const tableData = getFormattedData({data : getCombinedData(mapToObjectData, secondaryMappedData)});

    return (
        <div className={styles.container}>
            <BaseTable 
                wrapperFields={getWrapperFields()}
                columnProps={{ 
                    className : styles.columnRow
                }} 
                columnsData={columnData}
                tableData={tableData} 
                tableDataProps={{
                    className : styles.tableRow 
                }}
            />
        </div>
    )
}

export default ComparisonAnalysisTable;