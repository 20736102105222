import React, { useMemo } from "react";
import {map} from "lodash";
import moment from "moment";

import emptyNotification from "./../../../../../../../../../assets/images/empty_notification.svg";
import filledCalender from "./../../../../../../../../../assets/images/calender/filledCalender.svg";

import NotesCard from "./component/NotesCard";
import { getRespectiveFollowUpDate } from "./body.helpers";

import styles from "./body.module.css"

const Body = ({notes=[]}) =>{

    if(notes.length == 0 ){
        return (
            <div className={styles.container}>
                <img src={emptyNotification} className={styles.emptyIcon}/>
                <div className={styles.noContent}>
                    No Notes Added Yet!
                </div>
            </div>
        )
    }

    const followUpDate = useMemo(()=> getRespectiveFollowUpDate(notes),[notes]);

    return (
        <div className={styles.container}>
            <div className={styles.followUpContainer}>
                <img src={filledCalender} className={styles.calenderIcon} />
                <span>Follow-up Scheduled</span>
                <span className={styles.mainData}>{followUpDate!="-" ? moment(followUpDate).format("ddd, MMM DD"): "-"}</span>
            </div>
            {map(notes,(note)=>{
                return <NotesCard {...note}/>
            })}
        </div>
    )
}

export default Body;