import React, { useEffect, useState } from "react";
import moment from "moment";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drillDown from "highcharts/modules/drilldown";

import { CHART_NO_DATA } from "../../constants/noData";
import { hasChartData } from "../../helpers/chart";
import { getMinAndMaxGraphRange, getRespectiveSeries } from "./trendAnalysis.helpers";
import { trendAnalysisPlotBands, trendAnalysisPlotLines, plotOptions } from "./trendAnalysis.mapping";
import { SERIES_TYPES } from "./trendAnalysis.constants";

import "./trendAnalysis.module.css";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const TrendAnalysis = ({ comparisonAnalysisData = [], categories = [], trendAnalysisMin, trendAnalysisMax }) => {
  
  const {minRange, maxRange } = getMinAndMaxGraphRange(trendAnalysisMax);
  
  const plotBands = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? trendAnalysisPlotBands : []

  const plotLines = comparisonAnalysisData.length>0 && hasChartData(comparisonAnalysisData) ? trendAnalysisPlotLines : [];

  const options={
    chart: {
      type: "spline",
      height: 450,
      plotBorderWidth: 1,
      plotBorderColor: "#EAE7EC",
      events: {
        load: function() {
          if (!hasChartData(this.series[0].data) && this.showNoData) {
            this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        },
        update :function(){
          if (!hasChartData(this.series[0].data) && this.showNoData) {
            this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
          }
        }
      }
    },
    title: {
      text: null,
    },
    xAxis: {
        title: {
          text: null,
        },
        categories: categories,
        lineColor: "#EAE7EC",
        labels: {
          step:1,
          rotation:0,
          style: {
            color: 'var(--Base-Text-grey-midemp, #66636D)',
            fontFamily: '"Founders Grotesk"',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            letterSpacing: '0.084px'
          }        
      },
    },
    yAxis: {
        lineColor: "#EAE7EC",
        title: {
          text: null,
        },
        tickPositions: [0, 50, 63, 100, 140, 150, 200, 250, 300],
        min: 0,
        max: 200,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        labels: {
          useHTML:true,
          formatter: function () {
              if(this.value==63 || this.value==140){
                return '<span style="color: #7ACCAE; font-weight: bold;">' + this.value + '</span>';
              }
              return '<span style="color: black">' + this.value + '</span>';                ;
          }
        },
        plotBands: plotBands,
        plotLines
    },
    tooltip: {
      valueSuffix: ' mg/dL',
      formatter: function () {
        return '<b>' + moment(comparisonAnalysisData?.[this?.point?.index]?.timestamp).format("MMM DD") + " - " + moment(comparisonAnalysisData?.[this?.point?.index]?.timestamp).format('LT') + ' : ' + Math.round(this.y) + " mg/dL ";
      }
    },
    plotOptions,
    series: [
      getRespectiveSeries({type : SERIES_TYPES.SPLINE_CHART,data : comparisonAnalysisData}),
      getRespectiveSeries({type : SERIES_TYPES.DAILY_MAX_POINTS, data :  maxRange ,  trendAnalysisRangeData: trendAnalysisMax}),
      getRespectiveSeries({type : SERIES_TYPES.DAILY_MIN_POINTS, data :  minRange , trendAnalysisRangeData: trendAnalysisMin}),
    ],
    lang: {
      noData:"<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>",
    },
    noData: {
      useHTML: true,
      style: {
        color: '#66636D',
        minWidth:"250px",
        display:"flex",
        justifyContent:"center"
      },
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
  };

  const chartProps= (hasChartData(comparisonAnalysisData) && comparisonAnalysisData.length >0) ? {highCharts:Highcharts}: {highcharts:Highcharts};

  return (
    <div className={"container"} style={{ minWidth: "100%", marginTop:"16px" }}>
      <HighchartsReact
        {...chartProps}
        options={options}
      />
    </div>
  );
}

export default TrendAnalysis;