import React from "react";
import cx from "classnames";

import styles from "./calenderBlock.module.css";

const CalenderBlock = ({value, className, ...rest}) =>{
    return (
        <div className={cx(styles.container, className)} {...rest}>
            {value}
        </div>
    )
}

export default CalenderBlock