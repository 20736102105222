import React from "react";

import codeIcon from "./../../../../../../../assets/images/codeFrame.svg";

import styles from "./balancePlateCard.module.css";

const BalancePlateCard = () =>{

    return (
        <div className={styles.container}>
            <img src={codeIcon} className={styles.mainIcon}/>
            <div className={styles.mainContentContainer}>
                <div className={styles.mainLabel}>
                    We’ve temporarily paused this feature to ensure the highest standards of accuracy and reliability.
                </div>
                <div className={styles.mainContent}>
                    Our best developers are actively working on resolving the issue, and we’re committed to bringing it back better than ever. Thank you for your patience and understanding!
                </div>
            </div>
        </div>
    )
}

export default BalancePlateCard;