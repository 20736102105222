import React from 'react';
import cx from "classnames";
import moment from 'moment';

import arrowLeft from "./../../../../../../../assets/images/arrow/basicLeftArrow.svg";
import arrowRight from "./../../../../../../../assets/images/arrow/basicRightArrow.svg";
import activeArrowRight from "./../../../../../../../assets/images/arrow/activeBasicRightArrow.svg";

import styles from "./weeklyFilter.module.css";

const WeeklyFilter =  ({
        startDate="",
        containerClass="",
        endDate="",
        name,
        handlePrimaryDurationChange=()=>{},
        previous,
        onStartDate=false,
    }) =>{

    const handleLeftArrowClick = (event)=>{
        event.preventDefault();
        if(onStartDate){
            handlePrimaryDurationChange(name,moment(previous).subtract(6,'d').format('MM-DD-YYYY'));
            return;
        }
        handlePrimaryDurationChange(name,previous+1)
    }

    const handleRightArrowClick = (event) =>{
        event.preventDefault();
        if(onStartDate){
            handlePrimaryDurationChange(name,moment(previous).add(6,'d').format('MM-DD-YYYY'));
            return;
        }
        if(previous>0)
            handlePrimaryDurationChange(name, previous-1);
    }

    return (
        <div className={cx(styles.container,containerClass)}>
            <img src={arrowLeft} onClick={handleLeftArrowClick} className={styles.arrowIcon}/>
            <div className={styles.innerContainer}>
                <span>{startDate}</span>
                <span>-</span>
                <span>{endDate}</span>
            </div>
            <img src={ previous>0 ? activeArrowRight : arrowRight} onClick={handleRightArrowClick} className={styles.arrowIcon}/>
        </div>
    )
}

export default WeeklyFilter;