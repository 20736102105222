import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import drillDown from "highcharts/modules/drilldown";

import "./tirSingleLineChart.module.css";
import { COLOR_MAPPINGS } from "./colorMapping";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const TirSingleLineChart = ({tableData}) =>{

    drillDown(Highcharts);

    const options = {
        chart: {
            type: 'bar',
            height : 22,
            width : null,
            padding:0,
            margin: 0,
            events: {
                render: function () {
                  // Force chart resizing on render
                  this.reflow();
                }
            }
        },
        title: {
            text: null,
        },
        xAxis: {
            visible: false
        },
        tooltip:{
            borderWidth: 1,
            borderRadius: 5,
            borderShadow: "none",
            style: {
                color: '#333',
                fontSize: '10px',
                padding: '0px',
                position: "absolute"
            },
            positioner: function(boxWidth, boxHeight, point) {
                return {
                    x: point.plotX-boxWidth/2,
                    y: point.plotY-35
                };
            },
            outside: true,
            useHTML: true,
            formatter: function() {
                return '<div style="color: white; border-radius:8px;"><b>'
                 + this.y + 
                '</b></div>';
            },
            backgroundColor: '#2A292D',
        },
        yAxis: {
            visible: false
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                point: {
                    events: {
                        mouseOver: function () {
                            this.graphic.attr({
                                fill: COLOR_MAPPINGS[this.color]
                            });
                            },
                        mouseOut: function () {
                            this.graphic.attr({
                                fill: this.color
                            });
                        }
                    }
                }
            }
        },
        series: tableData,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        }
    }

    return (
        <div className={"container"}>
            <HighchartsReact highCharts={Highcharts} options={options}/>
        </div>
    )
}

export default TirSingleLineChart;