import React from "react";
import { useNavigate } from "react-router-dom";

import backIcon from "./../../../../assets/images/back_icon_purple.svg";
import Header from "../components/header";
import { NOTES_DISPLAY_STATUS } from "../patientProfile.constants";

import styles from "./container.module.css";

const UpperContainer = ({options, handlePageLeaveAction}) =>{

    const navigate = useNavigate();

    const handleBackOptionClick= ()=>{
        navigate("/");
    }

    const handleBackOptionAction = ()=>{
        handlePageLeaveAction(handleBackOptionClick)
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.upperHeader} onClick={handleBackOptionAction}>
                <img src={backIcon} className={styles.backIcon} />
                <span>
                    Back to patient list
                </span>
            </div>
            <Header options={options}/>
        </div>
    )
}

export default UpperContainer;