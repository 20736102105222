import React,{useEffect, useRef} from "react";
import { ProgressBar } from "react-bootstrap";

import withActionState from "../../../../hooks/withActionState";
import Modal from "./../../../../molecules/modals/Modal";

import highIcon from "./../../../../../assets/images/exclamation/yellowFilledExclamation.svg";
import lowIcon from "./../../../../../assets/images/criticleSquare.svg"

import { COLOR_CODING } from "./progressChart.constants";
import actionTypes from "./progressChart.actionTypes";
import actions from "./progressChart.actions";

import PopupHeader from "./components/Popup/Header";
import BalanedPlatePopupBody from "./components/BalancedPlatePopup/Body";

import BloodGlucosePopup from "./components/BloodGlucosePopup";

import styles from "./progressChart.module.css";
import "./secondary.module.css";


const GLUCOSE_ICON = {
  RED : lowIcon,
  YELLOW : highIcon
}

const ProgressChart = ({
    value, 
    handleTabChange, 
    color="", 
    suffix="%", 
    displayPopup, 
    date, 
    rowLabel, 
    modalMetrics,
    type, 
    dispatch = ()=>{}, 
    max=100
  }) =>{

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
          const inner = ref.current.querySelector(".progress-bar");
          if ( inner ) {
             inner.style.backgroundColor = COLOR_CODING[color] || "none";
          }
        }
    }, [ref]);

    const handlePopupToggle = (payload)=>{
      if(!displayPopup && !type){
        dispatch({actionType: actionTypes.GET_MODAL_DATA,payload:{date} })
      }
      dispatch({actionType: actionTypes.HANDLE_POPUP_TOGGLE, payload});
    }

    if(!value)
    {
      return (<div className={styles.noDataContainer}>
        NA
      </div>)
    }

    const headerProps={
      label : rowLabel,
      date,
    }

    const bodyProps={
      modalMetrics:modalMetrics,
      handleTabChange
    }

    return (
        <div className={styles.container} >
            { displayPopup &&
            <div className={styles.mainModalContainer}>
              <Modal crossIcon={require("./../../../../../assets/images/cancel/transparent_cacel.svg").default} bodyProps={bodyProps} toggleModal={handlePopupToggle} headerProps={headerProps} className={styles.modalContainer} HeaderComponent={PopupHeader} BodyComponent={type=="Balanced Plate" ? BalanedPlatePopupBody : BloodGlucosePopup}/>
            </div>}
            <div className={styles.container} onClick={handlePopupToggle}>
            {<div className={styles.textContainer}>
              <span className={styles.contentTypo}>{value? `${value} ${suffix}` :"-"}</span>
              {GLUCOSE_ICON?.[color.toUpperCase()] && <img src={GLUCOSE_ICON?.[color.toUpperCase()]} className={styles.contentIcon}/>}
            </div>}
            <ProgressBar ref={ref} max={max} now={value} className={styles.progressBar} style={{height:"4px"}}/>
          </div>
        </div>
    )
}

export default withActionState({Component: ProgressChart, action:actions });