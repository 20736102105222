

const COLOR_CODING = {
    green:"#7ACCAE",
    yellow:"#E4A944",
    red:"rgb(246, 180, 167)"
}

const LABEL_SUFFIX ={
    AVERAGE_ONE_HOUR_POST_PRANDIAL : ' 1hr'
}

export {
    COLOR_CODING,
    LABEL_SUFFIX
}