
const getRespectiveFollowUpDate = (notes)=>{
    let date = "-";
    for(let index=0;index<notes.length;index++){
        if(notes[index]?.followUp && notes[index]?.followUp!=""){
            let prev = new Date(notes[index]?.followUp);
            let curr = new Date();
            if(prev.getTime()<=curr.getTime()){
                return date;
            }
            date=notes[index]?.followUp;
        }
    }

    return date;
}

export {
    getRespectiveFollowUpDate
}