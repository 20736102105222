const getBillingActiveStatus = (billingDate="")=>{
    if(!billingDate || billingDate.length==0){
        return false;
    }

    let date = new Date();
    billingDate = new Date(billingDate);

    let difference = date.getTime()-billingDate.getTime();

    return (difference/(1000*3600*24))<0 
}

export {
    getBillingActiveStatus
}