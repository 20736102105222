import React from "react";

import redirectIcon from "./../../../../../../../assets/images/redirect/purpleRedirect.svg";

import styles from "./header.module.css";

const Header = ({label="",content="", handleCardClickAction=()=>{}}) =>{
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.primaryLabelTypography}>
                    {label}
                </div>
                <div className={styles.headerContentTypography}>
                    {content}
                </div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.viewDetailsButtonContainer} onClick={handleCardClickAction}>
                    <span className={styles.buttonContentTypo}>View Detailed Analysis</span>
                    <img src={redirectIcon} className={styles.redirectIcon} />
                </div>
            </div>
        </div>
    )
}

export default Header;