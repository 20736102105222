import React from 'react';
import cx from "classnames";

import TirSingleLineChart from '../../../../../../../../charts/TirSingleLineChart';
import highIcon from "./../../../../../../../../../assets/images/exclamation/yellowFilledExclamation.svg";
import lowIcon from "./../../../../../../../../../assets/images/criticleSquare.svg"

import { getDataInTableFormat } from './tableGraphBlock.helper';

import styles from "./tableGraphBlock.module.css";

const ANOMALIES_MAPPING = {
    RED : lowIcon,
    YELLOW : highIcon
}

const TableGraphBlock = ({primaryText="",secondaryText="", data, ...rest}) =>{

    const {inRange = {}, glucose, suffix = "mg/dL"} = data || {};


    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                <span className={styles.contentLabel}>Time-In-Range</span>
                <span className={styles.valueContainer}>
                    <img src={ANOMALIES_MAPPING[inRange?.["glucose-color"]?.split("_")?.[0]]} />
                    <span className={styles.secondaryText}>
                        <span className={styles.mainContentValue}>
                            {inRange?.glucose ? inRange?.glucose+" " : "No Data"}
                        </span>
                        {`${inRange?.glucose ? "%" : ""}`}
                    </span>
                </span>
            </div>
            {inRange.glucose && <TirSingleLineChart tableData={getDataInTableFormat(data)} />}
            <div className={styles.primaryText}>
                <span className={styles.contentLabel}>Avg. Glucose</span>
                <span className={styles.secondaryText}>
                    <img src={ANOMALIES_MAPPING[data?.["glucose-color"]?.split("_")?.[0]]} />
                    <span>
                        <span className={styles.mainContentValue}>
                            {glucose? glucose+" " : "No Data"}
                        </span>
                        {`${glucose ? suffix : ""}`}
                    </span>
                </span>
            </div>
        </div>
    )
}

export default TableGraphBlock;