import React, { useCallback, useMemo, useState } from "react";

import activeLeftArrow from "./../../../../../../../assets/images/arrow/active/basicLeftArrow.svg";
import activeRightArrow from "./../../../../../../../assets/images/arrow/active/basicRightArrow.svg";

import MonthCalender from "./components/MonthCalender/MonthCalender";

import { getRespectiveStartDate } from "./body.helpers";

import styles from "./body.module.css";

const Body = ({
        startDate="11-1-2024",
        handleCurrentDateUpdate = () => {},
        currentBack,
        setCurrentBack
    }) => {

    const {endDate} = useMemo(()=>getRespectiveStartDate({startDate}),[startDate,getRespectiveStartDate]);

    const handlePreviousMonthAction = useCallback(()=>{
        setCurrentBack(currentBack+1);
    },[setCurrentBack, currentBack]);

    const handleNextMonthAction = useCallback(()=>{
        if(currentBack>0){
            setCurrentBack(currentBack-1);
        }
    },[setCurrentBack,currentBack])

    return (
        <div className={styles.container}>
            <img src={activeLeftArrow} className={styles.arrowIcon} onClick={handlePreviousMonthAction} />
            <MonthCalender handleUpdateStartDate={handleCurrentDateUpdate} monthBack={currentBack+1} endDate={endDate} startDate={startDate} />
            <MonthCalender monthBack={currentBack} handleUpdateStartDate={handleCurrentDateUpdate} endDate={endDate} startDate={startDate}/>
            <img src={activeRightArrow} className={styles.arrowIcon} onClick={handleNextMonthAction}/>
        </div>
    )
}

export default Body;