import React, { useCallback, useEffect } from "react";
import { map } from "lodash";

import medicationIcon from "./../../../../../assets/images/medication.svg";
import PatientStatCard from "../PatientStatCard/PatientStatCard";
import RecentActivity from "../recentActivity";
import DailyMonitoringLog from "./components/DailyMonitoringLog";
import { statCards } from "./overviewTab.data";
import actions from "./overViewTab.actions";
import actionTypes from "./overViewTab.actionTypes";

import { getDailyMonitoringData, getFormattedChartStats, getFormattedStat, getWeeklyFormat } from "./overviewTab.helpers";
import { getUserFieldData } from "./overviewTab.helper";
import { NOTES_CONTENT_MAPPING } from "./overviewTab.constants";
import withActionState from "../../../../hooks/withActionState";

import styles from "./overViewTab.module.css";
import "./overViewTab.module.css";
import Notes from "./components/Notes";
import Billing from "./components/Billing/Billing";

const OverViewTab = ({
        statsCards=statCards, 
        overViewDurationTypes,
        handleTabChange,
        metricsResponse,
        recentTrends,
        handleOverDurationChange,
        dispatch,
        bloodGlucoseMetrics,
        micronutrientsInsightsResponse,
        micronutrientsInsightsResponseMap,
        balancePlateValues,
        balancePlate,
        handleNotesDisplayAction,
        notes,
        billingList=[],
        nextBillingDate,
        handleToggleLoaderAction
    }) =>{

    useEffect(()=>{
        dispatch({actionType: actionTypes.INIT_ACTION});
    },[]);

    const handleGetBillingListAction = useCallback(()=>{
        dispatch({actionType: actionTypes.GET_BILLING});
    },[dispatch])

    const {micronutrientsInsightsResponseMap : micronutrientsInsights ={}} = balancePlate || balancePlateValues || {};

    const dailyMonitoringData = getDailyMonitoringData({metrics : metricsResponse, handleTabChange,micronutrientsInsights, tableFields : ["OVERALL_TIME_IN_RANGE"]})
    
    const dayMappedData = map(dailyMonitoringData,(option =[])=>{
        let newObj = {};
        option.forEach((data,index)=>{
            newObj[`day ${index}`]={
                ...data,
                label : `day ${index}`,
                name : `day ${index}`,
            }
        })
        return newObj;
    });

    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                <span className={styles.primaryOverTextMain}>
                    {"Overview for "}
                </span>
                <span className={styles.daysText}> Last 7 days</span>
            </div>
            <div className={styles.statisticsOuterContainer}>
                <Notes 
                    headerProps={{ buttonContent : "Add New Note", label : NOTES_CONTENT_MAPPING.HEADER.LABEL, secondaryContent : NOTES_CONTENT_MAPPING.HEADER.CONTENT , handleNotesDisplayAction}}
                    bodyProps={{notes}}
                    notes={notes}
                />
                <Billing handleGetBillingListAction={handleGetBillingListAction} handleToggleLoaderAction={handleToggleLoaderAction} billingList={billingList} nextBillingDate={nextBillingDate}/>
                <RecentActivity className={styles.recentActivityContainer} headerProps={{img:medicationIcon,imageClass: styles.imageClass, containerClass: styles.recentTrendHeaderContainer}} recentTrends={recentTrends}/>
            </div>
            <div className={styles.statisticsContainer}>
                {
                    map(statsCards, ({ bodyStatFields, chartFields,...rest})=>{

                        const statistics = getFormattedStat({metrics : metricsResponse,selectedMetrics :bodyStatFields});
                        const chartStats = getFormattedChartStats({metrics : metricsResponse, chartType : chartFields});
                        const categories = getWeeklyFormat({numberOfDays : 7});

                        return <PatientStatCard 
                                micronutrientsInsightsResponse={micronutrientsInsightsResponse} 
                                bloodGlucoseMetrics={bloodGlucoseMetrics} 
                                micronutrientsInsightsResponseMap={micronutrientsInsightsResponseMap}
                                {...rest} 
                                handleTabChange={handleTabChange} 
                                bodyProps={{categories,statistics : statistics, chartStatistics :chartStats}} />
                    })
                }
            </div>
            <DailyMonitoringLog balancePlate={balancePlate || balancePlateValues || {}} overViewDurationTypes={overViewDurationTypes} handleOverDurationChange={handleOverDurationChange} wrapperFields={getUserFieldData()} tableData={dayMappedData}/>
        </div>
    )
}

export default withActionState({Component: OverViewTab, action: actions});