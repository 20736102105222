import React, { useCallback } from "react";

import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

import styles from "./patientStatCard.module.css";

const PatientStatCard = (props) =>{
    
    const {headerProps = {}, bodyProps = {}, CustomComponent,handleTabChange=()=>{},name } = props || {};

    const handleCardClickAction= useCallback(() =>{
        handleTabChange({label : name})
    },[handleTabChange])

    return (
        <div className={styles.container}>
            <Header {...headerProps} handleCardClickAction={handleCardClickAction}/>
            {CustomComponent && <CustomComponent {...props}/>}
            { !CustomComponent && <>
            <Body {...bodyProps} />
            </>}
        </div>
    )
}

export default PatientStatCard;