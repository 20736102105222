import React from "react";

import leftArrow from "./../../../../../assets/images/arrow/active/basicLeftArrow.svg";
import rightArrow from "./../../../../../assets/images/arrow/active/basicRightArrow.svg";

import nonActiveLeftArrow from "./../../../../../assets/images/arrow/nonActive/nonActiveLeft.svg";
import nonActiveRightArrow from "./../../../../../assets/images/arrow/nonActive/nonActiveRight.svg";

import styles from "./pagination.module.css";

const Pagination = ({totalPages=1, currentPage,handleShowNextListItems, handleShowPreviousListItems})=>{

    return (
        <div className={styles.container}>
        <img src={currentPage<=1 ? nonActiveLeftArrow : leftArrow} className={styles.arrow} onClick={handleShowPreviousListItems}/>
        <div className={styles.activeMainContainer}>
            {currentPage}
        </div>
        <img src={totalPages<=currentPage ? nonActiveRightArrow : rightArrow} onClick={handleShowNextListItems}/>
        </div>
    )
}

export default Pagination;